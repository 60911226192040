import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Row, Col, Card, Space, Button, Avatar } from "ebs-design";

import { UserProfileEntity } from "types";
import { Icon } from "components";

import { UserInterests } from "./UserInterests";
import { getUserDetailsCols } from "./userDetailsCols";

import { ModuleAction } from "features/modules-permissions";

export interface UserDetailsProps {
  user: UserProfileEntity;
  onDeleteClick: () => void;
  onBlockClick: () => void;
}
export const UserDetails = ({
  user,
  onDeleteClick,
  onBlockClick,
}: UserDetailsProps) => {
  const userDetailsCols = React.useMemo(() => getUserDetailsCols(user), [user]);
  const history = useHistory();

  return (
    <Card collapsible>
      <Card.Header>
        <div onClick={(e) => e.stopPropagation()}>
          <Space align="center" size="large">
            {user.photo?.url ? (
              <img
                src={user.photo?.url}
                alt="Profile"
                className="image-70x50"
              />
            ) : (
              <Avatar alt={user.first_name} circle size="big" />
            )}
            <div>
              <h2>
                {user.first_name} {user.last_name}
              </h2>
              <span className="small-text">{user.username}</span>
            </div>
          </Space>
        </div>
      </Card.Header>
      <Card.Body>
        <h4 className="mb-5">Interests</h4>
        <UserInterests interests={user.interests} />
        <div className="pady-2"></div>
        <h3>Details</h3>
        <Row>
          {userDetailsCols.map((col, i) => (
            <Col key={i}>
              {col.map((item, i) => (
                <div key={i} className="mary-4">
                  <span className="small-text color-secondary">{item[1]}</span>
                  <h4 className="fw-400">{item[0]}</h4>
                </div>
              ))}
            </Col>
          ))}
        </Row>
      </Card.Body>
      <Card.Footer>
        <Space justify="space-between">
          <Space>
            <ModuleAction module="send_gift">
              <Button
                type="ghost"
                prefix={<Icon type="status-sent" />}
                onClick={() => {
                  history.push(`/gifts/send/?users_id=${user.id}`);
                }}
              >
                Send Gift
              </Button>
            </ModuleAction>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${user.email}`}
            >
              <Button type="ghost" prefix={<Icon type="share" />}>
                Email User
              </Button>
            </a>
          </Space>
          <Space>
            {!user.is_suspended && (
              <ModuleAction module="users">
                <Button
                  type="primary"
                  prefix={<Icon type="block" />}
                  onClick={onBlockClick}
                >
                  Block
                </Button>
              </ModuleAction>
            )}

            <ModuleAction module="users">
              <Button
                type="primary"
                prefix={<Icon type="delete" />}
                onClick={onDeleteClick}
              >
                Delete
              </Button>
            </ModuleAction>
            <ModuleAction module="users">
              <Link to={`/users/${user.id}/edit`}>
                <Button type="primary" prefix={<Icon type="edit" />}>
                  Edit
                </Button>
              </Link>
            </ModuleAction>
          </Space>
        </Space>
      </Card.Footer>
    </Card>
  );
};
